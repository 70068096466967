<template>
  <div class="container">
    <div class="apartments__wrapper screen__wrapper">
      <Card v-for="renter in renters" :key="renter.id" :renter="renter" @click.native="info(renter)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="load"></infinite-loading>
  </div>
</template>

<script>
import Card from '../Card'
import Info from '../Info'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'RenterApartments',
  components: {
    Card,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    renters() {
      return this.$store.getters['renters/apartments/apartments']
    },
    hasMoreResults() {
      return this.renters.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['renters/apartments/pagination'].total
    },
    page() {
      return this.$store.getters['renters/apartments/page']
    }
  },
  methods: {
    info(renter) {
      this.$modal.display(Info, {renter}, {name: 'InfoRenter'})
    },
    async load() {
      this.isLoading = true
      try {
        await this.$store.dispatch('renters/apartments/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
